<template>
  <div @click="to(Jobitem)" class="TalentRecruitmentList-item f-cl">
    <div class="title-box f-c-b">
      <span class="title">{{ Jobitem.position }}</span>
      <span class="salary-range" v-show="Jobitem.salary1">{{ Jobitem.salary1 }} - {{ Jobitem.salary2 }} k</span>
    </div>
    <div class="enterprise-name" v-show="$route.path != '/RecruitList'">{{ Jobitem.enterpriseName }}</div>
    <div class="public-time f-c-b">
      <div>发布时间： {{ Jobitem.createDate }}</div>
      <div v-show="$route.path == '/RecruitList'">
        <span v-if="Jobitem.deleteFlag == 'N'">于 {{ Jobitem.expirationDate1 }} 过期</span>
        <span v-else>未发布</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    Jobitem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    to(Jobitem) {
      if (this.$route.path == '/TalentRecruitmentList') {
        this.$router.push({
          path: '/TalentRecruitmentDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              type: 'detail',
              id: Jobitem.postId
            }))
          }
        })
      } else if (this.$route.path == '/EnterpriseInternalRecruitment') {
        this.$router.push({
          path: '/TalentRecruitmentDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              type: 'enterprisePosition',
              id: Jobitem.postId
            }))
          }
        })
      }
      else {
        this.$router.push({
          path: '/RecruitPublish',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              type: 'detail',
            }))
          }
        })
        this.$setStorage('PositionDetail', Jobitem)
      }
    }
  },
}

</script>
<style lang='less' scoped>
.TalentRecruitmentList-item {
  padding: 15px 30px 15px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 2px silver;
  margin-bottom: 15px;

  .title-box {
    margin-bottom: 20px;

    .title {
      font-size: 22px;
      font-weight: bold;
    }

    .salary-range {
      color: #3ba73f;
      font-size: 18px;
    }
  }

  .enterprise-name {
    font-size: 18px;
  }

  .public-time {
    margin-top: 15px;
    font-size: 14px;

    span {
      color: red;
      font-size: 16px;
    }
  }
}
</style>
